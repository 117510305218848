import { ActionContext } from 'vuex';
import { NpsStoreState } from '@/interfaces/stores';
import { Metric } from '@/interfaces/visual';
import { getNPS } from '@/services/dashboardService';
import { mapNPSToMetrics } from '@/services/ModelMapperService';

export const getDefaultStateNpss = (): NpsStoreState => ({
    npss: [],
});

const npssStore = {
    namespaced: true,
    state: getDefaultStateNpss(),
    mutations: {
        resetState(state: NpsStoreState): void {
            Object.assign(state, getDefaultStateNpss());
        },
        setNpss(state: NpsStoreState, npss: Metric[]): void {
            state.npss = npss;
        },
    },
    actions: {
        async loadNpss({
            commit,
            dispatch,
            rootState,
        }: ActionContext<NpsStoreState, any>): Promise<any> {
            return getNPS(
                rootState.instance.instanceId,
                rootState.instance.waveId,
                rootState.instance.kpiConstruct,
                rootState.instance.locale,
            ).then((res) => {
                const npss = mapNPSToMetrics(res.data);
                commit('setNpss', npss);
            }).catch((error: Error) => {
                dispatch('errorStore/errorReceived', error, { root: true });
            });
        },
    },
};

export default npssStore;
