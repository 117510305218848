import moment from 'moment';
import i18nLocalization from '@/i18n/i18nLocalization';

import {
    CategoryRaterGroupMetric,
    CategoryScoreMetric,
    InteractiveComment,
    Metric,
    OpenQuestionMetric,
    PrimaryMetricScore,
    QuestionScoreMetric,
} from '@/interfaces/visual';
import {
    BffBreakoutResponseData,
    BffCategory,
    BffCategoryFavorable,
    BffHeatmapData,
    BffInteractiveComment,
    BffKpi,
    BffKpiFavorable,
    BffNPSData,
    BffOpenQuestionsResponseQuestion,
    BffQuestionsResponseQuestion,
    BffQuestionsResponseQuestionFavorable,
    BffRaterGroupScore,
    RaterGroup,
} from '@/interfaces/bff';
import { numberToLocaleString } from '@/helpers';
import { measurementLevelDefault } from '@/constants';

export const minScoreDiff = 0.05;

export const calculateScoreDiff = (
    min: number,
    max: number,
    scoreToCompare: number,
    scoreReference: number,
): number => (scoreToCompare - scoreReference) / (max - min);

export const calculateTrend = (
    min: number,
    max: number,
    mean: number,
    lastMean: number,
): number => {
    if (lastMean === null || lastMean === undefined || lastMean < 0) {
        return 0;
    }

    const trend = calculateScoreDiff(min, max, mean, lastMean);

    if (Math.abs(trend) >= minScoreDiff) {
        return trend;
    }

    return 0;
};

export const calculateTrendFavorable = (favorable: number, lastFavorable: number): number => {
    if (favorable < 0 || lastFavorable < 0) {
        return 0;
    }

    const trend = favorable - lastFavorable;

    if (Math.abs(trend) >= minScoreDiff) {
        return Math.round(trend * 100);
    }

    return 0;
};

const mapToQuestionBase = (
    id,
    text,
    measurementLevel,
    externalVariableName,
) => ({
    id,
    question: text,
    measurementLevel: measurementLevel || measurementLevelDefault,
    externalVariableName,
});

const mapToQuestionDataBase = (data, anonymous) => ({
    answered: data.responseTypeStats.answered,
    missing: data.responseTypeStats.missing,
    skipped: data.responseTypeStats.skipped,
    notSeen: data.responseTypeStats.notSeen,
    median: data.median ?? -1,
    standardDeviation: data.standardDeviation ?? -1,
    dimensions: data.stats.map((stat) => ({
        code: stat.code,
        title: stat.label,
        percent: stat.percentageOfPeople ? stat.percentageOfPeople * 100 : 0,
        type: stat.type,
        amount: stat.numberOfPeople,
    })),
    locked: anonymous,
});

const mapToQuestionDataMean = (data) => ({
    score: data.mean > -1 ? Math.round(data.mean * 10) / 10 : -1,
    maxScore: data.max,
    minScore: data.min,
    lastScore: data.lastMean,
    trend: calculateTrend(data.min, data.max, data.mean, data.lastMean),
    // eslint-disable-next-line no-prototype-builtins
    selfPerception: data.hasOwnProperty('selfPerception') ? data.selfPerception : -1,
});

const mapToQuestionDataFav = (data) => ({
    score: data.favorable > -1 ? Math.round(data.favorable * 100) : -1,
    maxScore: 100,
    minScore: 0,
    lastScore: data.lastFavorable,
    trend: calculateTrendFavorable(data.favorable, data.lastFavorable),
});

export const mapToQuestionScoreMetric = (
    {
        id,
        text,
        data,
        dataFiltered,
        measurementLevel,
        anonymous,
        externalVariableName,
        actionStatus,
        actionProperties,
    }: BffQuestionsResponseQuestion,
): QuestionScoreMetric => {
    const qData = dataFiltered || data;
    const qDataUnfiltered = dataFiltered ? data : undefined;

    const questionScoreMetricBase = mapToQuestionBase(
        id,
        text,
        measurementLevel,
        externalVariableName,
    );

    const questionScoreMetricDataBase = mapToQuestionDataBase(qData, anonymous);

    const questionScoreMetricMean = mapToQuestionDataMean(qData);

    const questionScoreMetric = {
        ...questionScoreMetricBase,
        ...questionScoreMetricDataBase,
        ...questionScoreMetricMean,
    } as QuestionScoreMetric;

    if (qDataUnfiltered) {
        questionScoreMetric.dataUnfiltered = {
            ...mapToQuestionBase(
                id,
                text,
                measurementLevel,
                externalVariableName,
            ),
            ...mapToQuestionDataBase(qDataUnfiltered, anonymous),
            ...mapToQuestionDataMean(qDataUnfiltered),
        };
    }

    if (actionStatus) {
        questionScoreMetric.action = {
            actionStatus,
            actionProperties,
        };
    }

    return questionScoreMetric;
};

export const mapToQuestionFavorableMetric = (
    {
        id,
        text,
        data,
        dataFiltered,
        measurementLevel,
        anonymous,
        externalVariableName,
    }: BffQuestionsResponseQuestionFavorable,
): QuestionScoreMetric => {
    const qData = dataFiltered || data;
    const qDataUnfiltered = dataFiltered ? data : undefined;

    const questionScoreMetricBase = mapToQuestionBase(
        id,
        text,
        measurementLevel,
        externalVariableName,
    );

    const questionScoreMetricDataBase = mapToQuestionDataBase(qData, anonymous);

    const questionScoreMetricFav = mapToQuestionDataFav(qData);

    const questionScoreMetric = {
        ...questionScoreMetricBase,
        ...questionScoreMetricDataBase,
        ...questionScoreMetricFav,
    } as QuestionScoreMetric;

    if (qDataUnfiltered) {
        questionScoreMetric.dataUnfiltered = {
            ...mapToQuestionBase(
                id,
                text,
                measurementLevel,
                externalVariableName,
            ),
            ...mapToQuestionDataBase(qDataUnfiltered, anonymous),
            ...mapToQuestionDataFav(qDataUnfiltered),
        };
    }

    return questionScoreMetric;
};

export const mapToOpenQuestionMetric = (responseQuestion: BffOpenQuestionsResponseQuestion): OpenQuestionMetric => ({
    id: responseQuestion.id,
    question: responseQuestion.text,
    openAnswers: responseQuestion.data.comments,
    type: responseQuestion.data.type,
});

export const mapToCategoryScoreMetric = ({ label, metaname, data }: BffCategory): CategoryScoreMetric => ({
    score: Math.round(data.mean * 10) / 10,
    maxScore: data.max,
    minScore: data.min,
    lastScore: data.lastMean,

    title: label,
    key: metaname,
    countScaleQuestions: data.countScaleQuestions,
    trend: calculateTrend(
        data.min,
        data.max,
        data.mean,
        data.lastMean,
    ),
    area: data.area,
    // eslint-disable-next-line no-prototype-builtins
    selfPerception: data.hasOwnProperty('selfPerception') ? data.selfPerception : -1,
    locked: false,
});

export const mapToCategoryScoreMetricFavorable = (
    {
        label, metaname, data,
    }: BffCategoryFavorable,
): CategoryScoreMetric => ({
    score: Math.round(data.favorable * 100),
    maxScore: 100,
    minScore: 0,
    lastScore: data.lastFavorable * 100,
    title: label,
    key: metaname,
    countScaleQuestions: data.countScaleQuestions,
    trend: calculateTrendFavorable(
        data.favorable,
        data.lastFavorable,
    ),
    area: data.area,
    locked: false,
});

export const getPrimaryMetricStatistic = (
    {
        bestInClass, lastMean, mean, min, max, lastWaveEndDate,
    }: BffKpi,
): PrimaryMetricScore => {
    const primaryMetric: PrimaryMetricScore = {
        score: Math.round(mean * 10) / 10,
        maxScore: max,
        minScore: min,
        lastScore: lastMean === -1 ? -1 : Math.round(lastMean * 10) / 10,
        trend: 0,
        bestInClass: bestInClass === -1 ? -1 : Math.round(bestInClass * 10) / 10,
        lastWaveEndDate,
        trends: [],
        locked: false,
    };

    // Historical trend
    /*  istanbul ignore else */
    if (lastMean >= 0) {
        const diff = mean - lastMean;
        const valuePrefix = Math.sign(diff) === 1 ? '+' : '';
        const trend = calculateTrend(min, max, mean, lastMean);
        const trendValueRounded = (Math.round(diff * 10) / 10);
        const trendValueString = numberToLocaleString(trendValueRounded, 1, 1);

        const lastWaveEndDateN = moment(lastWaveEndDate) as unknown as number;
        const now = moment(Date.now()) as unknown as number;
        const diffFromLastEnd = lastWaveEndDateN - now;
        let lastEnd = i18nLocalization.t('lastSurvey');
        /*  istanbul ignore else */
        if (diffFromLastEnd < 0 && lastWaveEndDate) {
            lastEnd = moment(lastWaveEndDate).format('LL');
        }

        let historicalText = `${i18nLocalization.t('kpiUnchanged')} ${lastEnd}`;
        let historicalTrend = 0;

        if (trend < 0) {
            historicalText = `${i18nLocalization.t('kpiDecreased')} ${lastEnd}`;
            historicalTrend = -1;
        }

        if (trend > 0) {
            historicalText = `${i18nLocalization.t('kpiIncreased')} ${lastEnd}`;
            historicalTrend = 1;
        }

        primaryMetric.trends.push({
            id: 'historical',
            trend: historicalTrend,
            value: `${valuePrefix}${trendValueString}`,
            text: historicalText,
        });
    }

    // Best in class trend
    /*  istanbul ignore else */
    if (bestInClass >= 0) {
        const diff = mean - bestInClass;
        const valuePrefix = Math.sign(diff) === 1 ? '+' : '';
        const trend = calculateTrend(min, max, mean, bestInClass);
        const trendValueRounded = (Math.round(diff * 10) / 10);
        const trendValueString = numberToLocaleString(trendValueRounded, 1, 1);

        let bestInClassText = '';
        let bestInClassTrend = 0;

        if (trend < 0) {
            bestInClassText = `${i18nLocalization.t('bestInClassDecreased')}`;
            bestInClassTrend = -1;
        }

        if (trend > 0) {
            bestInClassText = `${i18nLocalization.t('bestInClassIncreased')}`;
            bestInClassTrend = 1;
        }

        primaryMetric.trends.push({
            id: 'bestInClass',
            trend: bestInClassTrend,
            value: `${valuePrefix}${trendValueString}`,
            text: bestInClassText,
        });
    }

    return primaryMetric;
};

export const getPrimaryMetricStatisticFavorable = (
    {
        bestInClass, lastFavorable, favorable, lastWaveEndDate,
    }: BffKpiFavorable,
): PrimaryMetricScore => {
    const primaryMetric: PrimaryMetricScore = {
        score: Math.round(favorable * 100),
        maxScore: 100,
        minScore: 0,
        lastScore: lastFavorable === -1 ? -1 : Math.round(lastFavorable * 100),
        trend: 0,
        bestInClass: bestInClass === -1 ? -1 : Math.round(bestInClass * 100),
        lastWaveEndDate,
        trends: [],
        locked: false,
    };

    // Historical trend
    /*  istanbul ignore else */
    if (lastFavorable >= 0) {
        const diff = Math.round((favorable - lastFavorable) * 100);
        const valuePrefix = Math.sign(diff) === 1 ? '+' : '';

        const lastWaveEndDateN = moment(lastWaveEndDate) as unknown as number;
        const now = moment(Date.now()) as unknown as number;
        const diffFromLastEnd = lastWaveEndDateN - now;
        let lastEnd = i18nLocalization.t('lastSurvey');
        /*  istanbul ignore else */
        if (diffFromLastEnd < 0 && lastWaveEndDate) {
            lastEnd = moment(lastWaveEndDate).format('LL');
        }

        let historicalText = `${i18nLocalization.t('kpiUnchanged')} ${lastEnd}`;
        let historicalTrend = 0;

        if (diff < 0) {
            historicalText = `${i18nLocalization.t('kpiDecreased')} ${lastEnd}`;
            historicalTrend = -1;
        }

        if (diff > 0) {
            historicalText = `${i18nLocalization.t('kpiIncreased')} ${lastEnd}`;
            historicalTrend = 1;
        }

        primaryMetric.trends.push({
            id: 'historical',
            trend: historicalTrend,
            value: `${valuePrefix}${diff}`,
            text: historicalText,
        });
    }

    // Best in class trend
    /*  istanbul ignore else */
    if (bestInClass >= 0) {
        const diff = favorable - bestInClass;
        const value = `${Math.round(diff * 100)}`;
        const valuePrefix = Math.sign(diff) === 1 ? '+' : '';

        let bestInClassText = '';
        let bestInClassTrend = 0;

        if (diff < 0) {
            bestInClassText = `${i18nLocalization.t('bestInClassDecreased')}`;
            bestInClassTrend = -1;
        }

        if (diff > 0) {
            bestInClassText = `${i18nLocalization.t('bestInClassIncreased')}`;
            bestInClassTrend = 1;
        }

        primaryMetric.trends.push({
            id: 'bestInClass',
            trend: bestInClassTrend,
            value: `${valuePrefix}${value}`,
            text: bestInClassText,
        });
    }

    return primaryMetric;
};

export const mapToInteractiveComment = (
    {
        createdAt, comment, creator, score,
    }: BffInteractiveComment,
): InteractiveComment => ({
    createdAt: new Date(createdAt),
    bodytext: comment,
    creator,
    score,
});

export const mapNPSToMetrics = ({ nps }: BffNPSData): Metric[] => ([
    {
        label: `${i18nLocalization.t('goodWeek')}`,
        value: nps.top,
        state: 1,
    },
    {
        label: `${i18nLocalization.t('neutral')}`,
        value: nps.mid,
        state: 0,
    },
    {
        label: `${i18nLocalization.t('badWeek')}`,
        value: nps.low,
        state: -1,
    },
]);

export const mapToCategoryRaterGroupsMetric = (
    bffRaterGroup: BffRaterGroupScore,
    raterGroups: RaterGroup[],
): CategoryRaterGroupMetric => ({
    metaname: bffRaterGroup.metaname,
    label: raterGroups
        .filter((group) => group.metaname === bffRaterGroup.metaname)[0].label,
    score: Math.round(bffRaterGroup.value * 10) / 10,
    type: bffRaterGroup.type,
    minScore: bffRaterGroup.min,
    maxScore: bffRaterGroup.max,
    trend: 0,
    area: bffRaterGroup.area,
});

export const mapBreakoutToHeatmap = (
    { type, columns, data }: BffBreakoutResponseData,
    min: number,
    max: number,
    constructLabel: string,
    breakoutVariable: string,
    locale: string,
): BffHeatmapData => ({
    type,
    columns,
    data: data.map(({ label, values }) => ({
        externalVariableName: '',
        label,
        min,
        max,
        values,
    })),
    constructLabel,
    splitVariable: breakoutVariable,
    dataType: 'scale',
    locale,
});
