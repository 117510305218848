import { ActionContext } from 'vuex';
import { HeatmapStoreState } from '@/interfaces/stores';
import {
    getHeatmap,
    getBreakout,
    getHeatmapBySurveyFilters,
    raterGroupAllLabel,
} from '@/services/dashboardService';
import { mapBreakoutToHeatmap } from '@/services/ModelMapperService';
import {
    resultTypeAnonym,
    resultTypeMissing,
    resultTypeNormal,
    heatmapDataTypeCategory,
    measureFav,
} from '@/constants';
import {
    BffHeatmapData,
    BffHeatmapRow,
    BffBreakoutResponseData, BffSurveyFilter,
} from '@/interfaces/bff';
import i18nLocalization from '@/i18n/i18nLocalization';
import { QuestionScoreMetric } from '@/interfaces/visual';

export const getDefaultStateHeatmap = (): HeatmapStoreState => ({
    heatmapData: null,
    heatmapDataCard: null,
    dataType: heatmapDataTypeCategory,
    heatmapSurveyFilter: null,
});

function enrichHeatmapDataWithRelative(heatmapData: BffHeatmapData): BffHeatmapRow[] {
    return heatmapData.data.map((rowData) => {
        const dataWithRelative = rowData;
        dataWithRelative.relativeValues = rowData.values.map((val, index) => {
            const selfValue = rowData.values[0].value;
            if (val.value === -1 || selfValue === -1 || selfValue === null) {
                return {
                    value: -1,
                    type: resultTypeMissing,
                };
            }
            // First row is self perception, so we need the absolute value
            if (index === 0) {
                return {
                    value: val.value,
                    type: resultTypeNormal,
                };
            }

            if (val.value === null) {
                return {
                    value: val.value,
                    type: resultTypeAnonym,
                };
            }

            return {
                value: val.value - selfValue,
                type: resultTypeNormal,
            };
        });
        return dataWithRelative;
    });
}

function enrichHeatmapDataWithPercent(heatmapData: BffHeatmapData): BffHeatmapRow[] {
    const reducer = (acc: number, current: BffHeatmapRow) => (current.values[0].value ? acc + current.values[0].value : acc);
    const totalResponses = heatmapData.data.reduce(reducer, 0);

    return heatmapData.data.map((rowData) => {
        const dataWithPercent = rowData as BffHeatmapRow;

        dataWithPercent.values = rowData.values.map((valueCol) => {
            const valueColWithPercent = valueCol;
            valueColWithPercent.percentValue = -1;
            if (valueCol.type !== resultTypeAnonym) {
                const val = (valueCol.value && valueCol.value > -1) ? valueCol.value : 0;
                valueColWithPercent.percentValue = (val * 100) / totalResponses;
            }
            return valueColWithPercent;
        });

        return dataWithPercent;
    });
}

function mapHeatmapDataWithPercent(heatmapData: BffHeatmapData): BffHeatmapRow[] {
    return heatmapData.data.map((rowData) => {
        const dataWithPercent = rowData as BffHeatmapRow;

        dataWithPercent.values = rowData.values.map((valueCol) => {
            const valueColWithPercent = valueCol;
            valueColWithPercent.percentValue = -1;
            const val = valueCol.value;
            if (valueCol.type !== resultTypeAnonym && val && val > -1) {
                valueColWithPercent.percentValue = val * 100;
            }
            return valueColWithPercent;
        });

        return dataWithPercent;
    });
}

const heatmapStore = {
    namespaced: true,
    state: getDefaultStateHeatmap(),
    mutations: {
        resetState(state: HeatmapStoreState): void {
            Object.assign(state, getDefaultStateHeatmap());
        },
        setHeatmapData(state: HeatmapStoreState, heatmapData: BffHeatmapData): void {
            state.heatmapData = heatmapData;
        },
        setHeatmapDataCard(state: HeatmapStoreState, heatmapDataCard: BffHeatmapData): void {
            const allIndex = heatmapDataCard.columns.findIndex((el) => el === '#all#');
            heatmapDataCard.columns.splice(allIndex, 1);

            heatmapDataCard.data.forEach((entry) => {
                entry.values?.splice(allIndex, 1);
                entry.relativeValues?.splice(allIndex, 1);
            });

            state.heatmapDataCard = heatmapDataCard;
        },
        setDataType(state: HeatmapStoreState, dataType: string): void {
            state.dataType = dataType;
        },
        setHeatmapSurveyFilter(state: HeatmapStoreState, surveyFilter: BffSurveyFilter): void {
            state.heatmapSurveyFilter = surveyFilter;
        },
    },
    actions: {
        async loadHeatmapDataForRaterGroups(
            {
                commit,
                dispatch,
                rootState,
            }: ActionContext<HeatmapStoreState, any>,
            heatmapParams: { hasSelfPerception: boolean },
        ): Promise<any> {
            return getHeatmap(
                rootState.instance.instanceId,
                rootState.instance.waveId,
                rootState.instance.locale,
                rootState.instance.feedbackConstruct,
                rootState.heatmap.dataType,
            ).then((res) => {
                const heatmapData: BffHeatmapData = res.data;

                const allIndex = heatmapData.columns.findIndex((el) => el === '#all#');

                /*  istanbul ignore else */
                if (allIndex >= 0) {
                    heatmapData.columns[allIndex] = i18nLocalization.t(raterGroupAllLabel.toLowerCase()) as string;
                }

                /*  istanbul ignore else */
                if (heatmapParams.hasSelfPerception && heatmapData.data) {
                    heatmapData.data = enrichHeatmapDataWithRelative(heatmapData);
                }

                commit('setHeatmapData', heatmapData);
            }).catch((error: Error) => {
                dispatch('errorStore/errorReceived', error, { root: true });
            });
        },

        async loadCardHeatmapDataForRaterGroups(
            {
                commit,
                dispatch,
                rootState,
            }: ActionContext<HeatmapStoreState, any>,
        ): Promise<any> {
            return getHeatmap(
                rootState.instance.instanceId,
                rootState.instance.waveId,
                rootState.instance.locale,
                rootState.instance.feedbackConstruct,
                heatmapDataTypeCategory,
            ).then((res) => {
                const heatmapDataCard: BffHeatmapData = res.data;
                commit('setHeatmapDataCard', heatmapDataCard);
            }).catch((error: Error) => {
                dispatch('errorStore/errorReceived', error, { root: true });
            });
        },

        async loadHeatmapDataForBreakout(
            {
                commit,
                dispatch,
                rootState,
            }: ActionContext<HeatmapStoreState, any>,
            heatmapParams: {
                scoreMetric: QuestionScoreMetric,
                breakoutVariable: string,
            },
        ): Promise<any> {
            const { scoreMetric, breakoutVariable } = heatmapParams;
            return getBreakout(
                rootState.instance.instanceId,
                rootState.instance.waveId,
                scoreMetric.externalVariableName,
                breakoutVariable,
                rootState.instance.locale,
                rootState.surveyFilters.selectedFilterScales,
            ).then((res) => {
                const heatmapRawData: BffBreakoutResponseData = res.data;
                const allIndex = heatmapRawData.columns.findIndex((el) => el === '#all#');

                /*  istanbul ignore else */
                if (allIndex >= 0) {
                    heatmapRawData.columns[allIndex] = i18nLocalization.t('breakoutAll') as string;
                }

                const heatmapData = mapBreakoutToHeatmap(
                    heatmapRawData,
                    scoreMetric.minScore,
                    scoreMetric.maxScore,
                    scoreMetric.externalVariableName,
                    breakoutVariable,
                    rootState.instance.locale,
                );
                heatmapData.data = enrichHeatmapDataWithPercent(heatmapData);

                commit('setHeatmapData', heatmapData);
            }).catch((error: Error) => {
                dispatch('errorStore/errorReceived', error, { root: true });
            });
        },

        async loadHeatmapDataForMultiCategory(
            {
                commit,
                dispatch,
                rootState,
                state,
            }: ActionContext<HeatmapStoreState, any>,
        ): Promise<any> {
            const { dataType, heatmapSurveyFilter } = state;
            const { mainMeasure } = rootState.instance;
            return getHeatmapBySurveyFilters(
                rootState.instance.instanceId,
                rootState.instance.waveId,
                rootState.instance.locale,
                rootState.instance.feedbackConstruct,
                dataType,
                mainMeasure,
                heatmapSurveyFilter?.varname || '',
            ).then((res) => {
                const heatmapData: BffHeatmapData = res.data;

                const allIndex = heatmapData.columns.findIndex((el) => el === '#all#');

                /*  istanbul ignore else */
                if (allIndex >= 0) {
                    heatmapData.columns[allIndex] = i18nLocalization.t(raterGroupAllLabel.toLowerCase()) as string;
                }

                if (mainMeasure === measureFav) {
                    heatmapData.data = mapHeatmapDataWithPercent(heatmapData);
                }

                commit('setHeatmapData', heatmapData);
            }).catch((error: Error) => {
                dispatch('errorStore/errorReceived', error, { root: true });
            });
        },
    },
};

export default heatmapStore;
