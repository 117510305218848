
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { SelectInterface } from '@/interfaces/components';
import { OptionItem } from '@/interfaces/visual';

@Component({})
export default class Select extends Vue implements SelectInterface {
    @Prop({ default: [] }) options!: OptionItem[];
    @Prop() value!: string;

    emitInput(event: InputEvent): void {
        if (event.target) {
            const target = event.target as HTMLSelectElement;
            const { value } = target;

            if (this.value !== value) {
                this.$emit('input', value);
            }
        }
    }
}
