import { BffSurveyFilterResponse, BffSurveyFilterResponseData } from '@/interfaces/bff';

export const surveyFiltersResponseData: BffSurveyFilterResponseData = {
    locale: 'en_US',
    filters: [
        {
            id: 12,
            label: 'Tester',
            varname: 'tester',
            scale: [
                {
                    code: '0',
                    order: 1,
                    label: 'kein Tester',
                    missing: 0,
                },
                {
                    code: '1',
                    order: 2,
                    label: 'Tester',
                    missing: 0,
                }
            ],
        },
        {
            id: 13,
            label: 'Language',
            varname: 'language',
            scale: [
                {
                    code: '1',
                    order: 1,
                    label: 'English',
                    missing: 0,
                },
                {
                    code: '2',
                    order: 2,
                    label: 'Deutsch',
                    missing: 0,
                },
                {
                    code: '3',
                    order: 3,
                    label: 'French',
                    missing: 0,
                },
                {
                    code: '4',
                    order: 4,
                    label: 'Spain',
                    missing: 0,
                }
            ],
        },
        {
            id: 14,
            label: 'Gender',
            varname: 'u_gender',
            scale: [
                {
                    code: '1',
                    order: 1,
                    label: 'Not available',
                    missing: 0,
                },
                {
                    code: '2',
                    order: 2,
                    label: 'male',
                    missing: 0,
                },
                {
                    code: '3',
                    order: 3,
                    label: 'female',
                    missing: 0,
                },
            ],
        },
    ]
}

export const surveyFiltersResponse: BffSurveyFilterResponse = {
    status: 200,
    data: surveyFiltersResponseData,
};
