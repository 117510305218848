import { ActionContext } from 'vuex';
import { selectedFilterScale, SurveyFilterStoreState } from '@/interfaces/stores';
import { BffSurveyFilter } from '@/interfaces/bff';
import { getSurveyFilters } from '@/services/dashboardService';

export const getDefaultStateSurveyFilters = (): SurveyFilterStoreState => ({
    surveyFilters: [],
    selectedFilterScales: [],
});

const surveyFilterStore = {
    namespaced: true,
    state: getDefaultStateSurveyFilters(),
    mutations: {
        resetState(state: SurveyFilterStoreState): void {
            Object.assign(state, getDefaultStateSurveyFilters());
        },
        setSurveyFilters(state: SurveyFilterStoreState, surveyFilters: BffSurveyFilter[]): void {
            state.surveyFilters = surveyFilters;
        },
        setSelectedFilterScales(state: SurveyFilterStoreState, selectedScales: selectedFilterScale[]): void {
            state.selectedFilterScales = selectedScales;
        },
        deleteFilterScale(state: SurveyFilterStoreState, index: number): void {
            state.selectedFilterScales.splice(index, 1);
        },
    },
    actions: {
        async loadSurveyFilters({
            commit,
            dispatch,
            rootState,
        }: ActionContext<SurveyFilterStoreState, any>): Promise<any> {
            return getSurveyFilters(
                rootState.instance.instanceId,
                rootState.instance.waveId,
                rootState.instance.locale,
            ).then((res) => {
                commit('setSurveyFilters', res.data.filters);
            }).catch((error: Error) => {
                dispatch('errorStore/errorReceived', error, { root: true });
            });
        },
    },
};

export default surveyFilterStore;
