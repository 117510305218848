import { ActionContext } from 'vuex';
import { InteractiveCommentsStoreState } from '@/interfaces/stores';
import { InteractiveComment } from '@/interfaces/visual';
import { getInteractiveComments } from '@/services/dashboardService';
import { mapToInteractiveComment } from '@/services/ModelMapperService';

export const getDefaultStateInteractiveComments = (): InteractiveCommentsStoreState => ({
    interactiveComments: [],
});

const interactiveCommentsStore = {
    namespaced: true,
    state: getDefaultStateInteractiveComments(),
    mutations: {
        resetState(state: InteractiveCommentsStoreState): void {
            Object.assign(state, getDefaultStateInteractiveComments());
        },
        setInteractiveComments(state: InteractiveCommentsStoreState, interactiveComments: InteractiveComment[]): void {
            state.interactiveComments = interactiveComments;
        },
    },
    actions: {
        async loadInteractiveComments({
            commit,
            dispatch,
            rootState,
        }: ActionContext<InteractiveCommentsStoreState, any>): Promise<any> {
            return getInteractiveComments(
                rootState.instance.instanceId,
                rootState.instance.waveId,
                rootState.instance.commentConstruct,
                rootState.instance.locale,
            ).then((res) => {
                const interactiveComments = res.data.comments.map((comment) => mapToInteractiveComment(comment));
                commit('setInteractiveComments', interactiveComments);
            }).catch((error: Error) => {
                dispatch('errorStore/errorReceived', error, { root: true });
            });
        },
    },
};

export default interactiveCommentsStore;
