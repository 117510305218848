import cloneDeep from 'clone-deep';
import {
    BffCategory,
    BffCategoryFavorable, BffCategoryFavorableResponse,
    BffCategoryFavorableResponseData,
    BffCategoryResponse,
    BffCategoryResponseData,
    BffCategoryRaterGroupsResponse, BffCategoryRaterGroupsResponseData, BffRaterGroupScore,
} from '@/interfaces/bff';
import { raterGroupAll, raterGroupOther } from '@/fixtures/bff/raterGroups';

export const groupsFulfillingAnonymity = [
    raterGroupAll.metaname,
    raterGroupOther.metaname,
];

export const categoryScores: BffCategory[] = [
    {
        metaname: 'taking-action',
        label: 'Taking Action',
        data: {
            countScaleQuestions: 4,
            mean: 1.4,
            max: 5,
            lastMean: 1,
            min: 1,
            area: 'dev',
        }
    },
    {
        metaname: 'transformation',
        label: 'Transformation',
        data: {
            countScaleQuestions: 0,
            mean: 1.6,
            max: 5,
            lastMean: -1,
            min: 1,
            area: 'dev',
        }
    },
    {
        metaname: 'interpersonal',
        label: 'Interpersonal',
        data: {
            countScaleQuestions: 4,
            mean: 4.0,
            max: 5,
            lastMean: 5.0,
            min: 1,
            area: 'strength',
        }
    },
    {
        metaname: 'lorem-ipsum',
        label: 'Lorem Ipsum',
        data: {
            countScaleQuestions: 4,
            mean: 3.8,
            max: 5,
            lastMean: 3.8,
            min: 1,
            area: 'strength',
        }
    },
    {
        metaname: 'aquidicus-ed-magna',
        label: 'Aquidicus Ed Magna',
        data: {
            countScaleQuestions: 4,
            mean: 2.5,
            max: 5,
            lastMean: 2,
            min: 1,
            area: 'dev',
        }
    },
];

export const categoryScoresResponseData: BffCategoryResponseData = {
    categories: categoryScores,
};

export const categoryScoresResponse: BffCategoryResponse = {
    status: 200,
    data: categoryScoresResponseData,
};

// ------------------------------------------------------------------------------------------------

export const categoryScoresWithSelfPerception: BffCategory[] = [
    {
        metaname: 'taking-action',
        label: 'Taking Action',
        data: {
            countScaleQuestions: 4,
            mean: 4.4,
            max: 5,
            lastMean: 1,
            min: 1,
            area: 'dev',
            selfPerception: 1,
        }
    },
    {
        metaname: 'transformation',
        label: 'Transformation',
        data: {
            countScaleQuestions: 4,
            mean: 1.6,
            max: 5,
            lastMean: -1,
            min: 1,
            area: 'dev',
            selfPerception: 4,
        }
    },
    {
        metaname: 'interpersonal',
        label: 'Interpersonal',
        data: {
            countScaleQuestions: 4,
            mean: 4.0,
            max: 5,
            lastMean: 5.0,
            min: 1,
            area: 'strength',
            selfPerception: 4.0,
        }
    },
    {
        metaname: 'lorem-ipsum',
        label: 'Lorem Ipsum',
        data: {
            countScaleQuestions: 4,
            mean: 3.8,
            max: 5,
            lastMean: 3.8,
            min: 1,
            area: 'strength',
            selfPerception: 2.0
        }
    },
    {
        metaname: 'aquidicus-ed-magna',
        label: 'Aquidicus Ed Magna',
        data: {
            countScaleQuestions: 4,
            mean: 2.5,
            max: 5,
            lastMean: 2,
            min: 1,
            area: 'dev',
            selfPerception: 3.0
        }
    },
];
export const categoryScoresWithSelfPerceptionOneLess = cloneDeep(categoryScoresWithSelfPerception.slice(1,categoryScoresWithSelfPerception.length - 1));

const categoryScoresWithSelfPerceptionDup = cloneDeep(categoryScoresWithSelfPerception);
categoryScoresWithSelfPerceptionDup.forEach((cat) => cat.data.selfPerception === -1);
export const categoryScoresWithEmptySelfPerception = categoryScoresWithSelfPerceptionDup;


export const categoryScoresWithSelfPerceptionOneLessResponseData: BffCategoryResponseData = {
    categories: categoryScoresWithSelfPerceptionOneLess,
    groupsFulfillingAnonymity,
};

export const categoryScoresWithSelfPerceptionOneLessResponse: BffCategoryResponse = {
    status: 200,
    data: categoryScoresWithSelfPerceptionOneLessResponseData,
};


export const categoryScoresWithSelfPerceptionResponseData: BffCategoryResponseData = {
    categories: categoryScoresWithSelfPerception,
    groupsFulfillingAnonymity,
};

export const categoryScoresWithSelfPerceptionResponse: BffCategoryResponse = {
    status: 200,
    data: categoryScoresWithSelfPerceptionResponseData,
};



export const categoryScoresWithSelfPerceptionEmptyResponseData: BffCategoryResponseData = {
    categories: categoryScoresWithEmptySelfPerception,
    groupsFulfillingAnonymity,
};

export const categoryScoresWithSelfPerceptionEmptyResponse: BffCategoryResponse = {
    status: 200,
    data: categoryScoresWithSelfPerceptionEmptyResponseData,
};

// ------------------------------------------------------------------------------------------------

export const categoryScoresFavorable: BffCategoryFavorable[] = [
    {
        metaname: 'taking-action',
        label: 'Taking Action',
        data: {
            countScaleQuestions: 4,
            favorable: .2,
            lastFavorable: .1,
            area: 'dev',
        }
    },
    {
        metaname: 'transformation',
        label: 'Transformation',
        data: {
            countScaleQuestions: 4,
            favorable: .3,
            lastFavorable: -1,
            area: 'dev',
        }
    },
    {
        metaname: 'interpersonal',
        label: 'Interpersonal',
        data: {
            countScaleQuestions: 4,
            favorable: .8,
            lastFavorable: 1,
            area: 'strength',
        }
    },
    {
        metaname: 'lorem-ipsum',
        label: 'Lorem Ipsum',
        data: {
            countScaleQuestions: 4,
            favorable: .75,
            lastFavorable: .75,
            area: 'strength',
        }
    },
    {
        metaname: 'aquidicus-ed-magna',
        label: 'Aquidicus Ed Magna',
        data: {
            countScaleQuestions: 4,
            favorable: .5,
            lastFavorable: .3,
            area: 'dev',
        }
    },
];

export const categoryScoresFavorableResponseData: BffCategoryFavorableResponseData = {
    categories: categoryScoresFavorable,
};

export const categoryScoresFavorableResponse: BffCategoryFavorableResponse = {
    status: 200,
    data: categoryScoresFavorableResponseData,
};

// ------------------------------------------------------------------------------------------------

export const categoryScoresRaterGroups = [
    {
        metaname: 'sys_rg_self_assessment',
        value: 1,
        type: 'normal',
        area: 'strength',
        max: 5,
        min: 1
    },
    {
        metaname: 'all_sum',
        value: 2,
        type: 'normal',
        area: 'strength',
        max: 5,
        min: 1
    },
    {
        metaname: 'sys_rg_team',
        value: 3,
        type: 'normal',
        area: 'strength',
        max: 5,
        min: 1
    },
    {
        metaname: 'sys_rg_team2',
        value: 1,
        type: 'normal',
        area: 'dev',
        max: 5,
        min: 1
    },
    {
        metaname: 'sys_rg_peers',
        value: -1,
        type: 'anonymity',
        area: '',
        max: 5,
        min: 1
    },
    {
        metaname: 'sys_rg_others',
        value: -1,
        type: 'missing',
        area: '',
        max: 5,
        min: 1
    },
]

export const categoryScoresRaterGroupsResponseData: BffCategoryRaterGroupsResponseData = {
    constructLabel: 'label',
    raterGroups: categoryScoresRaterGroups,
    locale: 'en_US',
};

export const categoryScoresRaterGroupsResponse: BffCategoryRaterGroupsResponse = {
    status: 200,
    data: categoryScoresRaterGroupsResponseData,
};

// ------------------------------------------------------------------------------------------------

export const categoryScoresAggregated = [
    {
        metaname: 'collaboration_meta',
        label: 'collaboration',
        data: {
            area: 'dev',
            countScaleQuestions: 3,
            mean: 3,
            lastMean: -1,
            min: 1,
            max: 5,
        }
    },
    {
        metaname: 'communication_meta',
        label: 'communication',
        data: {
            area: 'strength',
            countScaleQuestions: 2,
            mean: 2,
            lastMean: -1,
            min: 1,
            max: 5,
        }
    },
    {
        metaname: 'health_meta',
        label: 'health',
        data: {
            area: 'dev',
            countScaleQuestions: 6,
            mean: 1,
            lastMean: -1,
            min: 1,
            max: 5,
        }
    },
    {
        metaname: 'agility_meta',
        label: 'agility',
        data: {
            area: 'strength',
            countScaleQuestions: 1,
            mean: 4.3,
            lastMean: -1,
            min: 1,
            max: 5,
        }
    },
]

export const categoryScoresAggregatedResponseData: BffCategoryResponseData = {
    categories: categoryScoresAggregated,
};

export const categoryScoresAggregatedResponse: BffCategoryResponse = {
    status: 200,
    data: categoryScoresAggregatedResponseData,
};


export const categoryScoresEmptyResponse: BffCategoryResponse = {
    status: 200,
    data: {
        categories: [],
    },
};
