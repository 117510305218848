
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { CockpitActionInterface } from '@/interfaces/visual';

@Component({})
export default class CockpitAction extends Vue implements CockpitActionInterface {
    @Prop() icon!: string;
    @Prop() title!: string;
    @Prop() teaser!: string;
    @Prop({ default: false }) isDataAvailable!: boolean;
    @Prop({ default: false }) showSkeletons!: boolean;
    @Prop({ default: false }) hideBtn!: boolean;
    @Prop({ default: false }) isNested!: boolean;

    clickHandler(): void {
        if (this.isCardClickable) {
            this.$emit('click');
        }
    }

    overCockpitAction(): void {
        if (this.isCardClickable) {
            this.$emit('overCockpitAction');
        }
    }

    outCockpitAction(): void {
        if (this.isCardClickable) {
            this.$emit('outCockpitAction');
        }
    }

    get isCardClickable(): boolean {
        return this.isDataAvailable && !this.hideBtn && !this.showSkeletons;
    }
}

