import { ActionContext } from 'vuex';
import { KpiState } from '@/interfaces/stores';
import { PrimaryMetricScore } from '@/interfaces/visual';
import { getKpi } from '@/services/dashboardService';
import {
    getPrimaryMetricStatistic,
    getPrimaryMetricStatisticFavorable,
} from '@/services/ModelMapperService';
import {
    measureFav,
} from '@/constants';
import {
    BffKpiFavorableResponseData, BffKpiResponseData,
} from '@/interfaces/bff';

export const getDefaultStateKpi = (): KpiState => ({
    kpi: null,
    constructLabel: '',
});

const kpiStore = {
    namespaced: true,
    state: getDefaultStateKpi(),
    mutations: {
        resetState(state: KpiState): void {
            Object.assign(state, getDefaultStateKpi());
        },
        setKpi(state: KpiState, kpi: PrimaryMetricScore): void {
            state.kpi = kpi;
        },
        setConstructLabel(state: KpiState, constructLabel: string): void {
            state.constructLabel = constructLabel;
        },
    },
    actions: {
        async loadKpi({ commit, dispatch, rootState }: ActionContext<KpiState, any>): Promise<any> {
            return getKpi(
                rootState.instance.instanceId,
                rootState.instance.waveId,
                rootState.instance.locale,
                rootState.instance.kpiConstruct || rootState.instance.feedbackConstruct,
                rootState.instance.mainMeasure,
            ).then((res) => {
                const dashboardData = res.data;

                let primaryMetricScore: PrimaryMetricScore | null;
                if (rootState.instance.mainMeasure === measureFav) {
                    const { kpi } = dashboardData as BffKpiFavorableResponseData;
                    primaryMetricScore = getPrimaryMetricStatisticFavorable(kpi);
                } else {
                    const { kpi } = dashboardData as BffKpiResponseData;
                    primaryMetricScore = getPrimaryMetricStatistic(kpi);
                }

                commit('setKpi', primaryMetricScore);
                commit('setConstructLabel', dashboardData.constructLabel);
            }).catch((error: Error) => {
                dispatch('errorStore/errorReceived', error, { root: true });
            });
        },
    },
};

export default kpiStore;
