import { ActionContext } from 'vuex';
import { CategoriesStoreState } from '@/interfaces/stores';
import { CategoryRaterGroupMetric, CategoryScoreMetric } from '@/interfaces/visual';
import {
    getCategories,
    getCategoriesAggregated,
    getCategoryResultForRaterGroups,
} from '@/services/dashboardService';
import {
    allArea,
    devArea,
    measureFav,
    strengthArea,
} from '@/constants';
import {
    BffCategory,
    BffCategoryFavorable,
} from '@/interfaces/bff';
import {
    mapToCategoryScoreMetric,
    mapToCategoryScoreMetricFavorable,
    mapToCategoryRaterGroupsMetric,
} from '@/services/ModelMapperService';
import { inIframe } from '@/services/browserApiService';
import Vue from 'vue';
import { showErrorNotification } from '@/store/articles';

export const getDefaultStateCategories = (): CategoriesStoreState => ({
    categoriesUnfiltered: [],
    categories: [],
    groupsFulfillingAnonymity: [],
    selectedCategoryKey: '',
    raterGroupsScores: [],
    creatingActionListener: false,
});

const categoriesStore = {
    namespaced: true,
    state: getDefaultStateCategories(),
    mutations: {
        resetState(state: CategoriesStoreState): void {
            Object.assign(state, getDefaultStateCategories());
        },
        setCategories(state: CategoriesStoreState, categories: CategoryScoreMetric[]): void {
            state.categories = categories;
        },
        setCategoriesUnfiltered(state: CategoriesStoreState, categories: CategoryScoreMetric[]): void {
            state.categoriesUnfiltered = categories;
        },
        setCategoryKey(state: CategoriesStoreState, categoryKey: string): void {
            state.selectedCategoryKey = categoryKey;
        },
        setRaterGroupsScores(state: CategoriesStoreState, raterGroupsScores: CategoryRaterGroupMetric[]): void {
            state.raterGroupsScores = raterGroupsScores;
        },
        setGroupsFulfillingAnonymity(state: CategoriesStoreState, groups: string[]): void {
            state.groupsFulfillingAnonymity = groups;
        },
    },
    getters: {
        devCategories(state: CategoriesStoreState): CategoryScoreMetric[] {
            return state.categories.filter((category) => category.area === devArea);
        },
        strengthCategories(state: CategoriesStoreState): CategoryScoreMetric[] {
            return state.categories.filter((category) => category.area === strengthArea);
        },
        devCategoriesUnfiltered(state: CategoriesStoreState): CategoryScoreMetric[] {
            return state.categoriesUnfiltered.filter((category) => category.area === devArea);
        },
        strengthCategoriesUnfiltered(state: CategoriesStoreState): CategoryScoreMetric[] {
            return state.categoriesUnfiltered.filter((category) => category.area === strengthArea);
        },
        selectedCategory(state: CategoriesStoreState): CategoryScoreMetric {
            return state.categories.filter((category) => category.key === state.selectedCategoryKey)[0];
        },
        devRaterGroupsScores(state: CategoriesStoreState): CategoryRaterGroupMetric[] {
            return state.raterGroupsScores.filter((raterGroupScore) => raterGroupScore.area === devArea);
        },
        strengthRaterGroupsScores(state: CategoriesStoreState): CategoryRaterGroupMetric[] {
            return state.raterGroupsScores.filter((raterGroupScore) => raterGroupScore.area === strengthArea);
        },
        minScale(state: CategoriesStoreState): number {
            return state.categoriesUnfiltered.reduce((acc, cat) => (acc < cat.minScore ? acc : cat.minScore), 0);
        },
        maxScale(state: CategoriesStoreState): number {
            return state.categoriesUnfiltered.reduce((acc, cat) => (acc > cat.maxScore ? acc : cat.maxScore), 0);
        },
    },
    actions: {
        async loadCategories({
            commit,
            dispatch,
            rootState,
            rootGetters,
        }: ActionContext<CategoriesStoreState, any>): Promise<any> {
            const isMultiRatersTemplate = rootGetters['instance/isMultiRatersTemplate'];
            const area = isMultiRatersTemplate ? rootState.multiRater.area : undefined;
            const isMultiCategoryTemplate = rootGetters['instance/isMultiCategoryTemplate'];

            return getCategories(
                rootState.instance.instanceId,
                rootState.instance.waveId,
                rootState.instance.locale,
                rootState.instance.feedbackConstruct,
                rootState.instance.mainMeasure,
                area,
                rootState.multiRater.selectedRaterGroupMetaname,
            ).then((res) => {
                const dashboardData = res.data;

                const bffCategories = dashboardData.categories as any[];

                if (dashboardData.groupsFulfillingAnonymity) {
                    commit('setGroupsFulfillingAnonymity', dashboardData.groupsFulfillingAnonymity);
                }

                let categories: CategoryScoreMetric[];

                if (rootState.instance.mainMeasure === measureFav) {
                    categories = bffCategories.map((question: BffCategoryFavorable) => mapToCategoryScoreMetricFavorable(question));
                } else {
                    categories = bffCategories.map((question: BffCategory) => mapToCategoryScoreMetric(question));
                }

                if (isMultiCategoryTemplate
                    || (isMultiRatersTemplate && area === allArea)
                ) {
                    commit('setCategoriesUnfiltered', categories);
                }

                commit('setCategories', categories);
            }).catch((error: Error) => {
                dispatch('errorStore/errorReceived', error, { root: true });
            });
        },

        async loadCategoriesAggregated({
            commit,
            dispatch,
            rootState,
        }: ActionContext<CategoriesStoreState, any>): Promise<any> {
            const { area } = rootState.multiRater;

            return getCategoriesAggregated(
                rootState.instance.instanceId,
                rootState.instance.guideId,
                rootState.instance.locale,
                rootState.instance.feedbackConstruct,
                area,
            ).then((res) => {
                const dashboardData = res.data;

                const bffCategories = dashboardData.categories as any[];

                if (dashboardData.groupsFulfillingAnonymity) {
                    commit('setGroupsFulfillingAnonymity', dashboardData.groupsFulfillingAnonymity);
                }

                const categories: CategoryScoreMetric[] = bffCategories.map((question: BffCategory) => mapToCategoryScoreMetric(question));

                if (area === allArea) {
                    commit('setCategoriesUnfiltered', categories);
                }

                commit('setCategories', categories);
            }).catch((error: Error) => {
                dispatch('errorStore/errorReceived', error, { root: true });
            });
        },

        async loadRaterGroupsScores({
            commit,
            dispatch,
            rootState,
        }: ActionContext<CategoriesStoreState, any>): Promise<any> {
            return getCategoryResultForRaterGroups(
                rootState.instance.instanceId,
                rootState.instance.waveId,
                rootState.instance.feedbackConstruct,
                rootState.categories.selectedCategoryKey,
            ).then((res) => {
                const dashboardData = res.data;

                const bffRaterGroups = dashboardData.raterGroups as any[];
                const sanitizedGroups = bffRaterGroups

                    .filter((bffGroup) => rootState.multiRater.raterGroups.filter((group) => bffGroup.metaname === group.metaname).length);
                const raterGroupsScores = sanitizedGroups
                    .map((group) => mapToCategoryRaterGroupsMetric(
                        group,
                        rootState.multiRater.raterGroups,
                    ));

                commit('setRaterGroupsScores', raterGroupsScores);
            }).catch((error: Error) => {
                dispatch('errorStore/errorReceived', error, { root: true });
            });
        },

        receivedActionMessage(
            _,
            event: Record<string, any>,
        ): void {
            const { data } = event;
            if (data?.action === 'externalImprovementsUpdate') {
                const { payload } = data;
                const { action } = payload;

                if (action === 'actionBoardMissing') {
                    const { sendActionData } = payload;
                    if (sendActionData?.additionalData?.categoryKey) {
                        showErrorNotification(Vue, sendActionData.additionalData.categoryKey);
                    }
                }
            }
        },

        addCategoryAction({ state, dispatch }: ActionContext<CategoriesStoreState, any>, category: CategoryScoreMetric): void {
            const eventSource = inIframe() ? window.top : window.self;
            const receivedMessage = (event: Record<string, any>) => {
                dispatch('receivedActionMessage', event);
            };
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            eventSource.postMessage({
                action: 'createImprovement',
                payload: {
                    categories: category.key,
                    sendActionData: {
                        additionalData: {
                            categoryKey: category.key,
                            showBoardLink: true,
                        },
                    },
                },
            }, '*');

            if (!state.creatingActionListener) {
                window.addEventListener('message', receivedMessage, false);
                state.creatingActionListener = true;
            }
        },
    },
};

export default categoriesStore;
