import defaultTheme from '@/constants/externalThemes';

export const measureFav: string = 'percentFavorable';
export const measureMean: string = 'mean';

export const measurementLevelDefault: string = 'default';
export const measurementLevelNominal: string = 'nominal';
export const measurementLevelOrdinal: string = 'ordinal';

export const devArea: string = 'dev';
export const strengthArea: string = 'strength';
export const allArea: string = 'all';

export const resultTypeNormal = 'normal';
export const resultTypeMissing = 'missing';
export const resultTypeAnonym = 'anonymity';
export const resultTypeSkipped = 'skipped';
export const resultTypeNotSeen = 'notSeen';

export const defaultNeutralChartColor: string = defaultTheme.basic.primaryShade.lighten1;
export const defaultLowScoreColor: string = defaultTheme.basic.primaryShade.darken3;
export const defaultHighScoreColor: string = defaultTheme.basic.primaryShade.lighten3;
export const defaultBackgroundColor: string = '#ffffff';
export const defaultThemeColor: string = defaultTheme.basic.primary.base;

export const defaultLocale: string = 'en_US';
export const defaultRefreshRate: number = 30;
export const defaultMeasure: string = measureMean;
export const defaultShowOverallKpi: boolean = false;
export const defaultShowResources: boolean = false;
export const defaultHistoricalTrend: boolean = false;
export const defaultAnonymityThreshold: number = 5;
export const defaultAnonymityThresholdActivated: boolean = false;

export const breakoutModeDefault = 'options';
export const breakoutModeBreakout = 'breakout';

export const heatmapDataTypeCategory = 'category';
export const heatmapDataTypeQuestion = 'question';
export const heatmapDataTypeScale = 'scale';

export const heatmapResultTypeAbsolute = 'absolute';
export const heatmapResultTypeRelative = 'relative';
export const heatmapResultTypePercent = 'percent';

export const actionStatusNone = 'none';
export const actionStatusTakeAction = 'takeAction';
export const actionStatusSeeAction = 'seeAction';

export const heatmapPercentColorStep = 20;

export const templateIds = {
    questionList: 'E0BAB2EF-14F4-4A09-964F-6558DC1CD875',
    multiCategory: '284B5916-2B82-46A8-9E7B-270CC913605A',
    raterGroups: 'A4Ac5677-8B70-424A-ACf7-6C1ED24135D8',
    vibes: '87D0E892-8998-4450-8D6C-EAC483CA0E0D',
    aggregated: 'A8114601-8BEE-42BA-B1C7-DB9B93B7159A',
};

export const selectAllKeys = 'all';
