import { AxiosResponse, CancelTokenSource } from 'axios';
import {
    BffCategoryResponse,
    BffInstanceResponse,
    BffOpenQuestionsResponse,
    BffQuestionsFavorableResponse,
    BffQuestionsResponse,
    BffResponseRatesResponse,
    BffKpiResponse,
    BffKpiFavorableResponse,
    BffCategoryFavorableResponse,
    BffRaterGroupsResponse,
    BffHeatmapResponse,
    BffNPSResponse,
    BffInteractiveCommentsResponse,
    BffCategoryRaterGroupsResponse,
    BffBreakoutResponse,
    BffSurveyFilterResponse,
} from '@/interfaces/bff';
import { CmsArticleResponse, CmsArticlesResponse } from '@/interfaces/cms';

import { ValidationError } from '@/helpers/error';
import httpClient from '@/clients/httpClient';
import {
    measureFav,
    measureMean,
    heatmapDataTypeCategory,
    allArea,
} from '@/constants';
import {
    dummyInstanceId,
    dummyInstanceIdWithBreakout,
    dummyInstanceIdFavorable,
    dummyInstanceIdMultiCategory,
    dummyInstanceIdMultiCategoryFavorable,
    dummyInstanceIdRaterGroups,
    dummyInstanceIds,
    dummyInstanceIdVibes,
    dummyInstanceIdWithFilter,
    dummyInstanceIdAggregated,
} from '@/fixtures';
import {
    instanceAggregatedResponse,
    instanceFavorableResponse,
    instanceMultiCategoryFavorableResponse,
    instanceMultiCategoryResponse,
    instanceRaterGroupsResponse,
    instanceResponse,
    instanceVibesResponse,
    instanceWithBreakoutResponse,
    instanceWithFilterResponse,
} from '@/fixtures/bff/instances';
import {
    scoredQuestionsFavorableResponse,
    scoredQuestionsResponse,
    scoredQuestionsWithSelfPerceptionResponse,
} from '@/fixtures/bff/questionScores';
import { unscoredQuestionsResponse } from '@/fixtures/bff/openQuestions';
import { responseRatesResponse } from '@/fixtures/bff/responseRates';
import {
    categoryScoresFavorableResponse,
    categoryScoresResponse,
    categoryScoresWithSelfPerceptionOneLessResponse,
    categoryScoresWithSelfPerceptionResponse,
    categoryScoresRaterGroupsResponse,
} from '@/fixtures/bff/categoryScores';
import {
    positiveTeamPerforanceScoreFavorableResponse,
    positiveTeamPerforanceScoreResponse,
} from '@/fixtures/bff/teamPerformances';
import {
    articlesResponse, getDummyArticleResponse,
} from '@/fixtures/cms/articles';
import { raterGroupsFullResponse } from '@/fixtures/bff/raterGroups';
import { bffHeatmapResponse, bffHeatmapResponseForQuestions } from '@/fixtures/bff/heatmaps';
import { bffNPSResponse } from '@/fixtures/bff/nps';
import { bffInteractiveCommentsResponse } from '@/fixtures/bff/interactiveComments';
import { breakoutResponse } from '@/fixtures/bff/breakouts';
import { surveyFiltersResponse } from '@/fixtures/bff/surveyFilters';
import { surveyFiltersHeatmapResponse } from '@/fixtures/bff/surveyFiltersHeatmap';
import { selectedFilterScale } from '@/interfaces/stores';
import { CategoryScoreMetric } from '@/interfaces/visual';

export const GET_INSTANCE_DATA = '/api/instance/{instanceId}';
export const GET_QUESTIONS_DATA = '/api/instance/{instanceId}/categories/{category}/question-list';
export const GET_AGGREGATED_QUESTIONS_DATA = '/api/instance/{instanceId}/categories/{category}/aggregated/area/question-list';
export const GET_QUESTIONS_DATA_AREA = '/api/instance/{instanceId}/categories/{category}/area/question-list';
export const GET_COMMENTS_DATA = '/api/instance/{instanceId}/categories/{category}/comments';
export const GET_RESPONSERATE_DATA = '/api/instance/{instanceId}/categories/{category}/response-rate';
export const GET_CATEGORIES_DATA = '/api/instance/{instanceId}/categories/{category}';
export const GET_CATEGORIES_DATA_AREA = '/api/instance/{instanceId}/categories/{category}/area';
export const GET_CATEGORIES_DATA_AGGREGATED = '/api/instance/{instanceId}/categories/{category}/aggregated/area';
export const GET_CATEGORY_DATA_RATER_GROUPS = '/api/instance/{instanceId}/categories/{category}/area/group-comparison';
export const GET_KPI = '/api/instance/{instanceId}/categories/{category}/kpi';
export const GET_ARTICLES = '/api/instance/{instanceId}/categories/{category}/articles';
export const GET_ARTICLE = '/api/instance/{instanceId}/articles/{locale}/{slug}';
export const GET_RATER_GROUPS = '/api/instance/{instanceId}/ratergroups';
export const GET_HEATMAP = '/api/instance/{instanceId}/categories/{categoryMetaname}/heatmap/rater-groups';

export const GET_HEATMAP_BY_SURVEY_FILTERS = '/api/instance/{instanceId}/categories/{categoryMetaname}/heatmap/survey-filters';
export const GET_NPS = '/api/instance/{instanceId}/categories/{categoryMetaname}/nps';
export const GET_INTERACTIVE_COMMENTS = '/api/instance/{instanceId}/categories/{categoryMetaname}/interactive-comments';
export const GET_BREAKOUT = '/api/instance/{instanceId}/question/{externalVarname}/breakout';
export const GET_SURVEY_FILTERS = '/api/instance/{instanceId}/filters';
export const POST_QUESTION_ACTION = '/api/instance/{instanceId}/action-relations';

export const raterGroupAll = 'all_sum';
export const raterGroupAllLabel = 'all';
export const raterGroupSelf = 'sys_rg_self_assessment';

function isEmpty(value: string) {
    return !value || value.toString() === '';
}

function validateEmpty(propertyName: string, propertyValue: string): void {
    if (isEmpty(propertyValue)) {
        throw new ValidationError(`${propertyName} is missing`);
    }
}

function validateIsSet(propertyName: string, propertyValue: any): void {
    if (propertyValue === undefined) {
        throw new ValidationError(`${propertyName} is missing`);
    }
}

function addFiltersToParams(selectedFilterScales: selectedFilterScale[]): Record<string, string> {
    const params = {};
    const uniqueFilterVarname = new Set(selectedFilterScales.map((scale) => scale.filterVarname));
    for (const varName of uniqueFilterVarname) { // eslint-disable-line no-restricted-syntax
        const filteredEntries = selectedFilterScales
            .filter((scale) => scale.filterVarname === varName);
        params[varName] = filteredEntries.length === 1
            ? filteredEntries[0].code
            : filteredEntries.map((scale) => scale.code);
    }

    return params;
}

export const getInstanceData = async (
    instanceId: string,
    waveId?: string,
): Promise<BffInstanceResponse> => {
    if (instanceId === dummyInstanceId) {
        return Promise.resolve(instanceResponse);
    }
    if (instanceId === dummyInstanceIdWithBreakout) {
        return Promise.resolve(instanceWithBreakoutResponse);
    }
    if (instanceId === dummyInstanceIdWithFilter) {
        return Promise.resolve(instanceWithFilterResponse);
    }
    if (instanceId === dummyInstanceIdFavorable) {
        return Promise.resolve(instanceFavorableResponse);
    }
    if (instanceId === dummyInstanceIdMultiCategory) {
        return Promise.resolve(instanceMultiCategoryResponse);
    }
    if (instanceId === dummyInstanceIdMultiCategoryFavorable) {
        return Promise.resolve(instanceMultiCategoryFavorableResponse);
    }
    if (instanceId === dummyInstanceIdRaterGroups) {
        return Promise.resolve(instanceRaterGroupsResponse);
    }
    if (instanceId === dummyInstanceIdVibes) {
        return Promise.resolve(instanceVibesResponse);
    }
    if (instanceId === dummyInstanceIdAggregated) {
        return Promise.resolve(instanceAggregatedResponse);
    }

    validateEmpty('Instance-ID', instanceId);

    const url = GET_INSTANCE_DATA.replace('{instanceId}', instanceId);
    const params = { params: { waveId } };

    return httpClient.get(url, params)
        .then((response: BffInstanceResponse) => response);
};

export const getScoredQuestions = async (
    source: CancelTokenSource | null,
    instanceId: string,
    waveId: string,
    category: string,
    // eslint-disable-next-line default-param-last
    measure: string = measureMean, // =mean|percentFavorable
    locale: string,
    selectedFilterScales: selectedFilterScale[],
    area?: string,
    raterGroup?: string,
): Promise<BffQuestionsResponse | BffQuestionsFavorableResponse> => {
    if (dummyInstanceIds.includes(instanceId)) {
        if (instanceId === dummyInstanceIdRaterGroups) {
            return Promise.resolve(scoredQuestionsWithSelfPerceptionResponse);
        }
        if (measure === measureFav) {
            return Promise.resolve(scoredQuestionsFavorableResponse);
        }
        return Promise.resolve(scoredQuestionsResponse);
    }

    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);
    validateEmpty('Category', category);

    let params = {
        waveId,
        locale,
        measure,
        area: (area && area !== allArea) ? area : undefined,
        raterGroup: (raterGroup && raterGroup !== raterGroupAll) ? raterGroup : undefined,
    };
    if (selectedFilterScales.length) {
        const filterParams = addFiltersToParams(selectedFilterScales);
        params = { ...params, ...filterParams };
    }

    let url = area ? GET_QUESTIONS_DATA_AREA : GET_QUESTIONS_DATA;
    url = url.replace('{instanceId}', instanceId).replace('{category}', category);
    return httpClient.get(
        url,
        {
            cancelToken: source?.token,
            params,
        },
    )
        .then((response: BffQuestionsResponse | BffQuestionsFavorableResponse) => response);
};

export const getAggregatedScoredQuestions = async (
    source: CancelTokenSource | null,
    instanceId: string,
    guideId: string,
    category: string,
    locale: string,
): Promise<BffQuestionsResponse | BffQuestionsFavorableResponse> => {
    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Guide-ID', guideId);
    validateEmpty('Category', category);

    const params = {
        guideId,
        locale,
    };

    let url = GET_AGGREGATED_QUESTIONS_DATA;
    url = url.replace('{instanceId}', instanceId).replace('{category}', category);
    return httpClient.get(
        url,
        {
            cancelToken: source?.token,
            params,
        },
    )
        .then((response: BffQuestionsResponse | BffQuestionsFavorableResponse) => response);
};

export const getOpenQuestions = async (
    source: CancelTokenSource | null,
    instanceId: string,
    waveId: string,
    category: string,
    locale: string,
    selectedFilterScales: selectedFilterScale[],
    raterGroup?: string,
): Promise<BffOpenQuestionsResponse> => {
    if (dummyInstanceIds.includes(instanceId)) {
        return Promise.resolve(unscoredQuestionsResponse);
    }

    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);
    validateEmpty('Category', category);

    let params = {
        waveId,
        locale,
        raterGroup: (raterGroup && raterGroup !== raterGroupAll) ? raterGroup : undefined,
    };

    if (selectedFilterScales.length) {
        const filterParams = addFiltersToParams(selectedFilterScales);
        params = { ...params, ...filterParams };
    }

    const url = GET_COMMENTS_DATA.replace('{instanceId}', instanceId).replace('{category}', category);
    return httpClient.get(
        url,
        {
            cancelToken: source?.token,
            params,
        },
    )
        .then((response: BffOpenQuestionsResponse) => response);
};

export const getResponseRates = async (
    instanceId: string,
    waveId: string,
    category: string,
): Promise<BffResponseRatesResponse> => {
    if (dummyInstanceIds.includes(instanceId)) {
        return Promise.resolve(responseRatesResponse);
    }

    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);
    validateEmpty('Category', category);

    const params = { params: { waveId } };
    let url = GET_RESPONSERATE_DATA;
    url = url.replace('{instanceId}', instanceId).replace('{category}', category);
    return httpClient.get(url, params)
        .then((response: BffResponseRatesResponse) => response);
};

export const getCategories = async (
    instanceId: string,
    waveId: string,
    locale: string,
    construct: string,
    // eslint-disable-next-line default-param-last
    measure: string = measureMean,
    area?: string,
    raterGroup?: string,
): Promise<BffCategoryResponse | BffCategoryFavorableResponse> => {
    if (dummyInstanceIds.includes(instanceId)) {
        if (instanceId === dummyInstanceIdRaterGroups) {
            if (raterGroup === 'sys_rg_peers') {
                return Promise.resolve(categoryScoresWithSelfPerceptionOneLessResponse);
            }
            return Promise.resolve(categoryScoresWithSelfPerceptionResponse);
        }
        if (measure === measureFav) {
            return Promise.resolve(categoryScoresFavorableResponse);
        }
        return Promise.resolve(categoryScoresResponse);
    }

    validateEmpty('Wave-ID', waveId);
    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Construct', construct);

    const params = {
        params: {
            waveId,
            locale,
            measure,
            area: (area && area !== allArea) ? area : undefined,
            raterGroup: (raterGroup && raterGroup !== raterGroupAll) ? raterGroup : undefined,
        },
    };

    let url = area ? GET_CATEGORIES_DATA_AREA : GET_CATEGORIES_DATA;
    url = url.replace('{instanceId}', instanceId);
    url = url.replace('{category}', construct);
    return httpClient.get(url, params)
        .then((response: BffCategoryResponse | BffCategoryFavorableResponse) => response);
};

export const getCategoriesAggregated = async (
    instanceId: string,
    guideId: string,
    locale: string,
    construct: string,
    area?: string,
): Promise<BffCategoryResponse | BffCategoryFavorableResponse> => {
    validateEmpty('Guide-ID', guideId);
    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Construct', construct);

    const params = {
        params: {
            guideId,
            locale,
            area: (area && area !== allArea) ? area : undefined,
        },
    };

    let url = GET_CATEGORIES_DATA_AGGREGATED;
    url = url.replace('{instanceId}', instanceId);
    url = url.replace('{category}', construct);
    return httpClient.get(url, params)
        .then((response: BffCategoryResponse | BffCategoryFavorableResponse) => response);
};

export const getCategoryResultForRaterGroups = async (
    instanceId: string,
    waveId: string,
    construct: string,
    categoryMetanameFilter: string,
): Promise<BffCategoryRaterGroupsResponse> => {
    if (dummyInstanceIds.includes(instanceId)) {
        return Promise.resolve(categoryScoresRaterGroupsResponse);
    }

    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);
    validateEmpty('Construct', construct);

    const params = {
        params: {
            waveId,
            categoryMetanameFilter,
        },
    };
    let url = GET_CATEGORY_DATA_RATER_GROUPS;
    url = url.replace('{instanceId}', instanceId);
    url = url.replace('{category}', construct);
    return httpClient.get(url, params)
        .then((response) => response);
};

export const getKpi = async (
    instanceId: string,
    waveId: string,
    locale: string,
    construct: string,
    measure: string = measureMean,
): Promise<BffKpiResponse | BffKpiFavorableResponse> => {
    if (dummyInstanceIds.includes(instanceId)) {
        if (measure === measureFav) {
            return Promise.resolve(positiveTeamPerforanceScoreFavorableResponse);
        }
        return Promise.resolve(positiveTeamPerforanceScoreResponse);
    }

    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);
    validateEmpty('Construct', construct);

    const params = { params: { waveId, locale, measure } };
    let url = GET_KPI;
    url = url.replace('{instanceId}', instanceId);
    url = url.replace('{category}', construct);
    return httpClient.get(url, params)
        .then((response: BffKpiResponse | BffKpiFavorableResponse) => response);
};

export const getArticles = async (
    instanceId: string,
    waveId: string,
    locale: string,
    cat: CategoryScoreMetric,
): Promise<CmsArticlesResponse> => {
    if (dummyInstanceIds.includes(instanceId)) {
        return Promise.resolve(articlesResponse);
    }
    const construct = cat.key;

    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);
    validateEmpty('Construct', construct);

    const params = { params: { waveId, locale } };
    let url = GET_ARTICLES;
    url = url.replace('{instanceId}', instanceId);
    url = url.replace('{category}', construct);
    return httpClient.get(url, params)
        .then((bffResponse: CmsArticlesResponse) => {
            const enrichedArticles = bffResponse.data.articles.map((article) => ({
                ...article,
                categoryLabel: cat.title,
            }));
            return {
                ...bffResponse,
                data: {
                    articles: enrichedArticles,
                },
            };
        });
};

export const getArticle = async (
    instanceId: string,
    fullSlug: string,
    waveId: string,
): Promise<CmsArticleResponse> => {
    validateEmpty('fullSlug', fullSlug);

    const dummyArticleResponse = getDummyArticleResponse(fullSlug);
    if (dummyArticleResponse.status === 200) {
        return dummyArticleResponse;
    }

    validateEmpty('Wave-ID', waveId);
    validateEmpty('Instance-ID', instanceId);

    let slug = fullSlug;
    const slugParts = fullSlug.split('/');
    let urlLang = '';
    if (slugParts.length === 3) {
        urlLang = slugParts[0].replace('-', '_');
        slug = fullSlug.substring(fullSlug.indexOf(slugParts[2]));
    }

    const params = { params: { waveId } };

    let url = GET_ARTICLE;
    url = url.replace('{instanceId}', instanceId);
    url = url.replace('{slug}', slug);
    url = url.replace('{locale}', urlLang);
    return httpClient.get(url, params)
        .then((response: CmsArticleResponse) => response);
};

export const getRaterGroups = async (
    instanceId: string,
    waveId: string,
    locale: string,
): Promise<BffRaterGroupsResponse> => {
    if (instanceId === dummyInstanceIdRaterGroups) {
        return Promise.resolve(raterGroupsFullResponse);
    }

    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);

    const params = { params: { waveId, locale } };
    let url = GET_RATER_GROUPS;
    url = url.replace('{instanceId}', instanceId);
    return httpClient.get(url, params)
        .then((response: BffRaterGroupsResponse) => response);
};

export const getHeatmap = async (
    instanceId: string,
    waveId: string,
    locale: string,
    categoryMetaname: string,
    dataType: string,
): Promise<BffHeatmapResponse> => {
    if (dummyInstanceIdRaterGroups === instanceId) {
        if (dataType === heatmapDataTypeCategory) {
            return Promise.resolve(bffHeatmapResponse);
        }
        return Promise.resolve(bffHeatmapResponseForQuestions);
    }

    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);
    validateEmpty('Category metaname', categoryMetaname);

    const params = { params: { waveId, locale, dataType } };
    let url = GET_HEATMAP;
    url = url.replace('{instanceId}', instanceId).replace('{categoryMetaname}', categoryMetaname);
    return httpClient.get(url, params)
        .then((response: BffHeatmapResponse) => response);
};

export const getHeatmapBySurveyFilters = async (
    instanceId: string,
    waveId: string,
    locale: string,
    categoryMetaname: string,
    // eslint-disable-next-line default-param-last
    dataType: string = heatmapDataTypeCategory,
    measure: string,
    surveyFilterVariable?: string,
): Promise<BffHeatmapResponse> => {
    const dummyIds = [
        dummyInstanceIdMultiCategory,
        dummyInstanceIdMultiCategoryFavorable,
    ];
    if (dummyIds.includes(instanceId)) {
        return Promise.resolve(surveyFiltersHeatmapResponse);
    }

    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);
    validateEmpty('Category metaname', categoryMetaname);

    const params = {
        params: {
            waveId,
            locale,
            surveyFilterVariable,
            dataType,
            measure,
        },
    };
    let url = GET_HEATMAP_BY_SURVEY_FILTERS;
    url = url.replace('{instanceId}', instanceId).replace('{categoryMetaname}', categoryMetaname);
    return httpClient.get(url, params)
        .then((response: BffHeatmapResponse) => response);
};

export const getNPS = async (
    instanceId: string,
    waveId: string,
    categoryMetaname: string,
    locale: string,
): Promise<BffNPSResponse> => {
    if (dummyInstanceIdVibes === instanceId) {
        return Promise.resolve(bffNPSResponse);
    }

    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);
    validateEmpty('Category metaname', categoryMetaname);

    const params = { params: { waveId, locale } };
    let url = GET_NPS;
    url = url.replace('{instanceId}', instanceId).replace('{categoryMetaname}', categoryMetaname);
    return httpClient.get(url, params)
        .then((response: BffNPSResponse) => response);
};

export const getInteractiveComments = async (
    instanceId: string,
    waveId: string,
    categoryMetaname: string,
    locale: string,
): Promise<BffInteractiveCommentsResponse> => {
    if (dummyInstanceIdVibes === instanceId) {
        return Promise.resolve(bffInteractiveCommentsResponse);
    }

    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);
    validateEmpty('Category metaname', categoryMetaname);

    const params = { params: { waveId, locale } };
    let url = GET_INTERACTIVE_COMMENTS;
    url = url.replace('{instanceId}', instanceId).replace('{categoryMetaname}', categoryMetaname);
    return httpClient.get(url, params)
        .then((response: BffInteractiveCommentsResponse) => response);
};

export const getBreakout = async (
    instanceId: string,
    waveId: string,
    externalVarname: string,
    breakoutVariable: string,
    locale: string,
    selectedFilterScales?: selectedFilterScale[],
): Promise<BffBreakoutResponse> => {
    if (dummyInstanceIdWithBreakout === instanceId) {
        return Promise.resolve(breakoutResponse);
    }

    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);
    validateEmpty('External varname', externalVarname);
    validateEmpty('Breakout varname', breakoutVariable);

    let params = {
        waveId,
        breakoutVariable,
        locale,
    };

    if (selectedFilterScales && selectedFilterScales.length) {
        const filterParams = addFiltersToParams(selectedFilterScales);
        params = { ...params, ...filterParams };
    }

    let url = GET_BREAKOUT;
    url = url.replace('{instanceId}', instanceId).replace('{externalVarname}', externalVarname);
    return httpClient.get(
        url,
        {
            params,
        },
    )
        .then((response: BffBreakoutResponse) => response);
};

export const getSurveyFilters = async (
    instanceId: string,
    waveId: string,
    locale: string,
): Promise<BffSurveyFilterResponse> => {
    if (dummyInstanceIds.includes(instanceId)) {
        return Promise.resolve(surveyFiltersResponse);
    }

    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);

    const params = { params: { waveId, locale } };
    let url = GET_SURVEY_FILTERS;
    url = url.replace('{instanceId}', instanceId);
    return httpClient.get(url, params)
        .then((response: BffSurveyFilterResponse) => response);
};

export const saveQuestionAction = async (
    instanceId: string,
    waveId: string,
    questionId: number | undefined,
    actionId: number | undefined,
): Promise<AxiosResponse<boolean>> => {
    validateEmpty('Instance-ID', instanceId);
    validateEmpty('Wave-ID', waveId);
    validateIsSet('Question-ID', questionId);
    validateIsSet('Action-ID', actionId);

    const payload = {
        waveId: parseInt(waveId, 10),
        questionId,
        actionId,
    };
    let url = POST_QUESTION_ACTION;
    url = url.replace('{instanceId}', instanceId);
    return httpClient.post(url, payload)
        .then((response) => response);
};
