import axios, { AxiosHeaders } from 'axios';
import {
    InternalError,
    AuthorizationError,
    AuthenticationError,
    NotFoundError,
    NotAvailableError,
    CancelledError,
} from '@/helpers/error';

const instance = axios.create({
    timeout: 40000,
});

instance.interceptors.request.use((request) => {
    const { params, data } = request;
    const mutatedConfig = request;

    if (params || data) {
        let accessToken;
        const waveId = params ? params.waveId : data.waveId;
        const guideId = params ? params.guideId : data.guideId;
        if (waveId) {
            // Read token for default templates dashboard
            accessToken = localStorage.getItem(`token_wave_${waveId}`);
        } else if (guideId) {
            // Read token for aggregated templates dashboard
            accessToken = localStorage.getItem(`token_guide_${guideId}`);
        }
        if (accessToken !== null) {
            if (!mutatedConfig.headers) {
                mutatedConfig.headers = {};
            }
            (mutatedConfig.headers as AxiosHeaders).set('Authorization', `Bearer ${accessToken}`);
        }
    }
    return mutatedConfig;
});

instance.interceptors.response.use((response) => response, (error) => {
    if (axios.isCancel(error)) {
        throw new CancelledError('Request cancelled');
    } else {
        const errorResponse = error;
        const { response } = error;

        if (error.code && error.code === 'ECONNABORTED') {
            throw new InternalError('Connection aborted');
        }

        if (response) {
            const { status, request, data } = response;

            if (status && status === 401) {
                const errorType = data?.details?.type || 'unknown';
                const url = request ? request.responseURL : '';
                throw new AuthenticationError('Authentication failed', errorType, url);
            }

            if (status && status === 403) {
                throw new AuthorizationError('Authorization failed');
            }

            if (status && status === 404) {
                throw new NotFoundError('Not found');
            }

            if (status && status === 503) {
                throw new NotAvailableError('Not available');
            }
        }

        throw new InternalError('API error', errorResponse);
    }
});

export default instance;
