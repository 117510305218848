import {
    BffBreakoutResponse,
    BffBreakoutResponseData,
    BffBreakoutData,
} from '@/interfaces/bff';

export const breakoutData: BffBreakoutData[] = [
    {
        code: 1,
        label: 'Answer option 1',
        values: [
            {
                value: null,
                type: 'normal'
            },
            {
                value: null,
                type: 'normal'
            },
            {
                value: null,
                type: 'missing'
            },
            {
                value: -1,
                type: 'missing'
            }
        ]
    },
    {
        code: 2,
        label: 'Answer option 2',
        values: [
            {
                value: 6,
                type: 'normal',
            },
            {
                value: 3,
                type: 'normal',
            },
            {
                value: 3,
                type: 'normal',
            },
            {
                value: -1,
                type: 'missing'
            }
        ]
    },
    {
        code: 3,
        label: 'Answer option 3',
        values: [
            {
                value: null,
                type: 'normal'
            },
            {
                value: null,
                type: 'normal'
            },
            {
                value: null,
                type: 'normal'
            },
            {
                value: -1,
                type: 'missing'
            }
        ]
    },
    {
        code: 4,
        label: 'Answer option 4',
        values: [
            {
                value: 4,
                type: 'normal',
            },
            {
                value: 2,
                type: 'normal',
            },
            {
                value: 2,
                type: 'normal',
            },
            {
                value: -1,
                type: 'missing'
            }
        ]
    },
    {
        code: 5,
        label: 'Answer option 5',
        values: [
            {
                value: null,
                type: 'normal'
            },
            {
                value: null,
                type: 'normal'
            },
            {
                value: null,
                type: 'normal'
            },
            {
                value: -1,
                type: 'missing'
            }
        ]
    }
];

export const breakoutResponseData: BffBreakoutResponseData = {
    type: 'absolute',
    columns: [
        '#all#',
        'Not available',
        'male',
        'female'
    ],
    data: breakoutData,
    constructLabel: 'v_1',
    splitVariable: 'u_gender',
    dataType: 'scale',
    locale: 'en_US'
}

export const breakoutResponse: BffBreakoutResponse = {
    status: 200,
    data: breakoutResponseData
}
