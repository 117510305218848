import Vuex, { ActionContext } from 'vuex';
import Vue from 'vue';
import authentication from '@/store/authentication';
import reloadStore from '@/store/reloadStore';
import categories from '@/store/categories';
import multiRater from '@/store/multiRater';
import instance from '@/store/instance';
import commentQuestions from '@/store/commentQuestions';
import responseRates from '@/store/responseRates';
import scoredQuestions from '@/store/scoredQuestions';
import articles from '@/store/articles';
import kpi from '@/store/kpi';
import heatmap from '@/store/heatmap';
import interactiveComments from '@/store/interactiveComments';
import npss from '@/store/npss';
import surveyFilters from '@/store/surveyFilters';
import portalCommunication from '@/store/portalCommunication';
import errorStore from '@/store/error';

Vue.use(Vuex);

export const modules = {
    articles,
    authentication,
    categories,
    instance,
    kpi,
    multiRater,
    reload: reloadStore,
    scoredQuestions,
    commentQuestions,
    responseRates,
    heatmap,
    interactiveComments,
    npss,
    surveyFilters,
    errorStore,
    portalCommunication,
};

export const actions = {
    resetState({ commit }: ActionContext<any, any>): void {
        Object.keys(modules).forEach((module) => {
            commit(`${module}/resetState`);
        });
    },
};

export default new Vuex.Store({
    strict: false,
    modules,
    actions,
});
